import React from 'react'

const Footer = () => (
  <footer className="footer">
    <p className="footer__credits">
      Illustration by {' '}
      <a
        href="https://www.instagram.com/ayshaillustrates"
        target="_blank"
        rel="noopener noreferrer"
        className="footer__credits__link"
      >
        Aysha Awwad
      </a>
      {' '}
      &mdash;
      {' '}
      Website by {' '}
      <a
        href="https://colinmeinke.com"
        target="_blank"
        rel="noopener noreferrer"
        className="footer__credits__link"
      >
        Colin Meinke
      </a>
    </p>
  </footer>
)

export default Footer