import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import Instagram from '../images/svg/instagram.svg'
import Twitter from '../images/svg/twitter.svg'
import YouTube from '../images/svg/youtube.svg'

const Logo = () => (
  <StaticQuery
    query={graphql`
      query Logo {
        file(relativePath: { eq: "logo.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 80, maxHeight: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <div className="header__branding__logo">
          <Image
            fluid={data.file.childImageSharp.fluid}
            alt="Peeking Duck"
            width="40"
            height="40"
          />
        </div>
      </>
    )}
  />
)

let lastScrollPosition = 0

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hidden: false }
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll() {
    const currentScrollPosition = window.scrollY
    const direction = currentScrollPosition > lastScrollPosition ? 'down' : 'up'

    if (direction === 'up' && this.state.hidden) {
      this.setState({ hidden: false })
    } else if (direction === 'down' && !this.state.hidden && currentScrollPosition > 70) {
      this.setState({ hidden: true })
    }

    lastScrollPosition = currentScrollPosition
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    return (
      <header className="header" data-hidden={this.state.hidden}>
        {typeof this.props.homeCallback === 'function'
          ? (
            <a
              className="header__branding"
              href="/"
              onClick={e => {
                e.preventDefault()
                this.props.homeCallback()
              }}
            >
              <Logo />
            </a>
          )
          : (
            <Link to="/" className="header__branding">
              <Logo />
            </Link>
          )
        }

        <div className="header__nav">
          <nav
            role="navigation"
            className="header__nav-list"
          >
            <Link
              to="/about"
              className="header__nav-list__item"
            >
              <span className="header__nav-list__item__title">
                About
              </span>
            </Link>

            <Link
              to="/places"
              className="header__nav-list__item"
            >
              <span className="header__nav-list__item__title">
                Places
              </span>
            </Link>

            <a
              href="https://twitter.com/peekingduckco"
              target="_blank"
              rel="noopener noreferrer"
              className="header__nav-list__item header__nav-list__item--icon header__nav-list__item--twitter"
            >
              <Twitter />
            </a>

            <a
              href="https://instagram.com/peekingduckco"
              target="_blank"
              rel="noopener noreferrer"
              className="header__nav-list__item header__nav-list__item--icon header__nav-list__item--instagram"
            >
              <Instagram />
            </a>

            <a
              href="https://www.youtube.com/channel/UCS3MGjqsnFQoxL7nAGQc_Tg/videos"
              target="_blank"
              rel="noopener noreferrer"
              className="header__nav-list__item header__nav-list__item--icon header__nav-list__item--youtube"
            >
              <YouTube />
            </a>
          </nav>
        </div>
      </header>
    )
  }
}

export default Header