import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import './layout.css'

const Layout = ({
  title,
  description,
  og = [],
  twitter = [],
  children,
  pathname = '/',
  homeCallback,
  prev,
  next,
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            siteUrl
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <html lang="en" />
          <title>{title || data.site.siteMetadata.title}</title>
          <meta name="description" content={description || data.site.siteMetadata.description} />

          <meta property="og:site_name" content={data.site.siteMetadata.title} />
          <meta property="og:url" content={`${data.site.siteMetadata.siteUrl}${pathname}`} />

          {og.filter(({ content }) => content).map(({ property, content }, i) => (
            <meta property={`${property}`} content={content} key={i} />
          ))}

          <meta name="twitter:url" content={`${data.site.siteMetadata.siteUrl}${pathname}`} />

          {twitter.filter(({ content }) => content).map(({ name, content }, i) => (
            <meta name={`twitter:${name}`} content={content} key={i} />
          ))}

          {prev && <link rel="prev" href={`${data.site.siteMetadata.siteUrl}${prev}`} />}
          {next && <link rel="next" href={`${data.site.siteMetadata.siteUrl}${next}`} />}

          <link
            rel="alternate"
            type="application/rss+xml"
            title="Peeking Duck"
            href={`${data.site.siteMetadata.siteUrl}/rss.xml`}
          />

          <link rel="canonical" href={`${data.site.siteMetadata.siteUrl}${pathname}`} />

          <body itemScope itemType="http://schema.org/Blog" />

          <link
            href="https://fonts.googleapis.com/css?family=Antic+Slab"
            rel="stylesheet"
          />
        </Helmet>
        <div className="container">
          <Header homeCallback={homeCallback} />
          {children}
          <Footer />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
